import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from '../services/error-handling.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  username: string = '';
  password: string = '';
  loginForm!: FormGroup;


  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private errorHandlingService: ErrorHandlingService
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required])
    });
  }

  onLogin(): void {
    const { valid, value } = this.loginForm;

    if (valid) {
      this.loaderService.show();
      this.authenticationService
        .login(value)
        .pipe(
          mergeMap((res) => {
            if (res.status === 200) {
              return this.authenticationService.checkUserType();
            } else if (res.status === 219 || res.status === 220) {
              this.router.navigate(['auth'], {
                state: { response: res.body },
              });
            }
            return of(null);
          }),
          catchError((response: HttpErrorResponse) => {
          if (response.status === 401) {
            this.toastr.error(response.error.message);
          }
          this.errorHandlingService.handleBackendError(response);
          return of(null);
        })
        )
        .subscribe(() => {
          this.loaderService.hide();
        });
    }
  }

}
