import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { Token } from '../model/token.interface';


export const blacklist = [
  environment.auth,
  environment.refresh
];
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  refreshing = false;
  refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private injector: Injector,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler):  any {
    request = this.setLanguageHeaders(request);
    const isblacklistedURL = !!blacklist.find(blUrl => request.url.includes(blUrl) && !request.url.includes(environment.logout));
    if (!isblacklistedURL) {
      const token = localStorage.getItem('token');
      if (token) {
        request = this.addToken(request, token);
      }
      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handleAuthError(request, next);
          } else {
            return throwError(() => error);
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }

  addToken(request: HttpRequest<any>, token: string): any {
    return request.clone({
      setHeaders: {
        SpxAuth: `Bearer ${token}`,
      }
    });
  }

  setLanguageHeaders(request: HttpRequest<any>): any {
    return request.clone({
      setHeaders: {
        'Accept-Language': 'en',
      }
    });
  }

  handleAuthError(request: HttpRequest<any>, next: HttpHandler): any {
    const authService = this.injector.get<AuthenticationService>(AuthenticationService);
    if (!this.refreshing) {
      this.refreshing = true;
      this.refreshTokenSubject.next('');

      return authService.refreshTokens()
        .pipe(
          switchMap((result: Token) => {
            this.refreshing = false;
            this.refreshTokenSubject.next(result.token);
            return next.handle(this.addToken(request, result.token));
          }),
          catchError((error) => {
            this.refreshing = false;
            authService.onRefreshTokenExpired();
            return throwError(() => error);
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }
}
