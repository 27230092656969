import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class InitializeService {

  constructor(
    private userService: UserService,
  ) {}

  initialize(): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.userService.getUser()
          .pipe(
            catchError(() => {
              return of(null);
            })
          );
    } else {
      return of(null);
    }
  }
}
